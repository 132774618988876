import "./style.css";

import { createClickjacker } from "../clickjacker";

const nav = document.querySelector(".header-nav");

if (nav) {
  const parentItems = Array.from(
    document.querySelectorAll(".header-nav--children")
  ).map((el) => el.parentElement);

  let destroyClickjacker = null;
  const closeNav = () => {
    nav.removeAttribute("data-open");
    parentItems.forEach((item) =>
      item.removeAttribute("data-children-visible")
    );
  };
  nav.querySelector(".header-nav--btn")?.addEventListener("click", () => {
    destroyClickjacker?.();

    if (!nav.getAttribute("data-open")) {
      nav.setAttribute("data-open", "1");
      destroyClickjacker = createClickjacker(() => {
        closeNav();
        destroyClickjacker();
      });
    } else closeNav();
  });

  parentItems.forEach((item) =>
    item
      .querySelector("button")
      .addEventListener("click", () =>
        item.getAttribute("data-children-visible")
          ? item.removeAttribute("data-children-visible")
          : item.setAttribute("data-children-visible", "1")
      )
  );
}
